import React from "react";

import Footer from "../components/footer";
import NavbarLight from "../components/navbarlight";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <div>
      <SEO keywords={[`crow`, `video`, `remote`, `interviews`]} title="About" />

      <NavbarLight />

      <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Remote-first work is the future.
            </h2>
          </div>
          <div className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
            <p>
              We believe that preparing for remote-first work is the most
              responsible way to run a sustainable company. You&#8217;ll hire
              the best candidates and keep the happiest employees.
            </p>
            <p className="mt-3">
              Even if you expect most of your employees to work from your
              offices, a remote-first posture will go a long way in preparing
              for the future.
            </p>
            <p className="mt-3">
              Crow builds tools that help every company recruit and retain
              employees, whatever their remote-work experience.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;
